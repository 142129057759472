import { apiGet, apiPost, apiDelete, apiPut, apiUpload } from '../utils';
import axios from 'axios';

let fdHeaders = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const getXxxUrl = id => apiGet('/xxx', id);

export const getLogOutUrl = () => apiGet('/web/logout');
export const getRemindLis = () => apiGet('/remind/lis');
export const getRemindNotifyAllread = () => apiGet('/remind/notify/allread');

export const getTaskType = () => apiGet('/task/type');

export const getDailyBoxLis = () => apiGet('/daily/box/lis');
export const getDailyMyself = () => apiGet('/daily/myself');

export const getAnnounceLis = () => apiGet('/announce/lis');

export const getDiskUploadLis = () => apiGet('/disk/upload/lis');

export const postSms = data => apiPost('/sms', data); //opt:'modpassword' account:'' 忘记密码发验证码
export const postPasswordMod = data => apiPost('/password/mod', data); //password:'' account:'' sms:' 改密码
export const getCourseLisUrl = key => apiGet(`/course/lis?key=${key}`);

export const getCourseInfo = data => apiPost('/course/info', data);
export const postAddMainCourse = data => apiPost('/course/add/main', data);
export const postAddSubCourse = data => apiPost('/course/add/sub', data);
export const postDelCourse = data => apiPost('/course/del', data);
export const postEditCourseName = data => apiPost('/course/label/mod', data);
export const postSaveCurrPage = data => apiPost('/course/save', data);

export const postTaskUser = data => apiPost('/task/user', data);
export const postTaskLisAll = data => apiPost('/task/lis/all', data);
export const postTaskLisMyself = data => apiPost('/task/lis/myself', data);
export const postTaskJoinDel = data => apiPost('/task/join/del', data); //删除参与人
export const postTaskJoinNew = data => apiPost('/task/join/new', data); //添加参与人
export const postTaskJoinMod = data => apiPost('/task/join/mod', data); //覆盖参与人

export const postLoginUrl = data => apiPost('/web/login', data); //账号密码登录
export const postWxLogin = data => apiPost('/wx/login', data); //微信登录
export const postUserinfoWxBind = data => apiPost('/userinfo/wx/bind', data); //微信解绑

export const postTaskType = () => apiGet('/task/type');
export const postTaskLisWho = data => apiPost('/task/lis/who', data);
export const postTaskNew = data => apiPost('/task/new', data);
export const postTaskDel = data => apiPost('/task/del', data);
export const postTaskInfoLis = data => apiPost('/task/info/lis', data);
export const postTaskStatusMod = data => apiPost('/task/status/mod', data);
export const postTaskTypeMod = data => apiPost('/task/type/mod', data);
export const postTaskLevelMod = data => apiPost('/task/level/mod', data);
export const postTaskStimeMod = data => apiPost('/task/stime/mod', data);
export const postTaskEtimeMod = data => apiPost('/task/etime/mod', data);
export const postTaskContentMod = data => apiPost('/task/content/mod', data);
export const postTaskTitleMod = data =>
  apiPost('/task/title/mod', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
export const postTaskSubMod = data => apiPost('/task/sub/mod', data);
export const postTaskSubNew = data => apiPost('/task/sub/new', data);
export const postTaskSubDel = data => apiPost('/task/sub/del', data);
export const postTaskSubLis = data => apiPost('/task/sub/lis', data);
export const postTaskCommentLis = data => apiPost('/task/comment/lis', data);
export const postTaskRecordLis = data => apiPost('/task/record/lis', data);
export const postTaskChargeMod = data => apiPost('/task/charge/mod', data);
export const postTaskWorkTimeLis = data => apiPost('/task/worktime/lis', data);
export const postTaskWorkTimeNew = data => apiPost('/task/worktime/new', data);
export const postTaskWorkTimeMod = data => apiPost('/task/worktime/mod', data);
export const postTaskWorkTimeDel = data => apiPost('/task/worktime/del', data);
export const postTaskUploadLis = data => apiPost('/task/upload/lis', data);
export const postTaskUploadNew = data => apiPost('/task/upload/new', data);
export const postTaskUploadDel = data => apiPost('/task/upload/del', data);
export const postTaskUploadFix = data => apiPost('/task/upload/fix', data); //修改图片名字
export const postTaskDownload = data => apiPost('/task/download', data); //下载附件
export const postTaskDownloadAll = data => apiPost('/task/download/all', data); //下载全部附件
export const postTaskCommentNew = data => apiPost('/task/comment/new', data);
export const postRemindNew = data => apiPost('/remind/new', data);
export const postRemindDel = data => apiPost('/remind/del', data);
export const postRemindMod = data => apiPost('/remind/mod', data);
export const postRemindRead = data => apiPost('/remind/read', data);
export const postRemindNotifyLis = data => apiPost('/remind/notify/lis', data);

export const postDailyBoxNew = data => apiPost('/daily/box/new', data); //收纳箱新建
export const postDailyStatusLis = data => apiPost('/daily/status/lis', data); //日事情按status查询列表
export const postDailyStatusRank = data => apiPost('/daily/status/rank', data); //日事情改变列表顺序
export const postDailyDeal = data =>
  apiPost('/daily/deal', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
export const postDailyReturnfile = data =>
  apiPost('/daily/returnfile', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const postDailyBoxMod = data => apiPost('/daily/status/mod', data);
export const postDailyStatusNameMod = data =>
  apiPost('/daily/status/name/mod', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }); //修改状态名
export const postDailyBoxInfoLis = data => apiPost('/daily/box/info/lis', data);
export const postDailyBoxInfoMod = data => apiPost('/daily/box/info/mod', data);
export const postDailyBoxSubMod = data =>
  apiPost('/daily/box/sub/mod', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }); //修改日事清子任务的
export const postDailyBoxRecordLis = data => apiPost('/daily/box/record/lis', data);
export const postDailyBoxSubLis = data => apiPost('/daily/box/sub/lis', data);
export const postDailyBoxSubNew = data => apiPost('/daily/box/sub/new', data);
export const postDailyBoxSubDel = data => apiPost('/daily/box/sub/del', data);
export const postDailyUploadLis = data => apiPost('/daily/upload/lis', data);
export const postDailyUploadNew = data => apiPost('/daily/upload/new', data);
export const postDailyDownload = data => apiPost('/daily/download', data);
export const postDailyDownloadAll = data => apiPost('/daily/download/all', data);
export const postDailySearch = data => apiPost('/daily/search', data);


export const postCommunicateChatUser = data => apiPost('/communicate/chat/user', data);
export const postChatwsMsSend = data => apiPost('/chatws/ms/send', data); //发消息
export const postChatwsMsInfo = data => apiPost('/chatws/ms/info', data); //查找聊天记录
export const postChatwsTeam = data => apiPost('/chatws/team', data);
export const postCommunicateChatGroupInfo = data => apiPost('/communicate/chat/group/info', data);
export const postChatwsGroup = data => apiPost('/chatws/group', data); //获取用户所有群组
export const postChatwsGroupMod = data => apiPost('/chatws/group/mod', data); //修改群详情
export const postChatwsGroupDel = data => apiPost('/chatws/group/del', data); //解散群组
export const postChatwsGroupNew = data => apiPost('/chatws/group/new ', data); //创建群聊
export const postChatwsGroupUserLis = data => apiPost('/chatws/group/user/lis', data); //查询群成员
export const postChatwsGroupUsersNew = data => apiPost('/chatws/group/users/new', data); //新增群成员
export const postChatwsMsLis = data => apiPost('/chatws/ms/lis', data); //聊天列表
export const postChatwUserLis = data => apiPost('/chatws/users/new', data); //单聊
export const postChatwGroupTofirst = data => apiPost('/chatws/group/tofrist', data); //群聊置顶


export const postAnnounceInboxLis = data => apiPost('/announce/inbox/lis', data);
export const postAnnounceNew = data => apiPost('/announce/new', data);
export const postAnnounceDel = data => apiPost('/announce/del', data);
export const postAnnounceInfo = data => apiPost('/announce/info', data);
export const postAnnounceSimpleInfo = data => apiPost('/announce/simple/info', data);
export const postAnnounceMod = data => apiPost('/announce/mod', data);
export const postAnnounceBack = data => apiPost('/announce/back', data);

export const postDiskFolderLis = data => apiPost('/disk/folder/lis', data);

//个人信息相关
export const postUserInfoLis = data => apiPost('/userinfo/lis', data); //查看个人信息
export const postUserInfoMod = data => apiPost('/userinfo/mod', data); //修改用户信息
export const postUserInfoPhoneChange = data => apiPost('/userinfo/phone/change', data); //获取验证码
export const postUserInfoCheckSms = data => apiPost('/userinfo/check/sms', data); //获取验证码

export const deleteXXX = data => apiDelete('/xxx', data);
export const deleteDailyBox = data => apiDelete('/daily/box', data);
export const deleteDailyUploadDel = data => apiDelete('/daily/upload/del', data);

export const uploadFile = (data, onUploadProgress) =>
  axios({
    //上传文件的请求，返回url data为fd fd.append('uploadfile', f文件);
    url: 'https://up.eai9.com', //请求上传文件返回url
    method: 'post',
    data: data,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress,
  });
//部门
//成员
export const sectorLis = data => apiPost('/sector/lis', data); //部门列表
export const sectorNew = data => apiPost('/sector/new', data); //增添部门
export const sectorMod = data => apiPost('/sector/mod', data); //修改部门
export const sectorDel = data => apiPost('/sector/del', data); //删除部门
export const sectorUserNew = data => apiPost('/sector/user/new', data); //添加部门成员
export const sectorUserDel = data => apiPost('/sector/user/del', data); //删除部门成员
export const sectorUserMod = data => apiPost('/sector/user/mod', data); //修改部门成员
export const sectorUserStatus = data => apiPost('/sector/user/able', data); //部门成员0启用1禁用
export const getDepartUserLis = data => apiGet('/sector/users/lis',data); // 创建任务时 获取部门里的成员列表
//角色
export const sectorRoleLis = data => apiGet('/sector/role/lis', data); //角色列表
export const sectorRoleMod = data => apiPost('/sector/role/mod', data); //角色权限
export const sectorRoleDel = data => apiPost('/sector/role/del', data); //角色权限
export const sectorRoleAdd = data => apiPost('/sector/role/add', data); //角色添加
export const sectorRoleDepAdd = data => apiPost('/sector/mod/add',data); // 角色部门添加
export const sectorRoleDepDel = data => apiPost('/sector/mod/del',data); // 角色部门删除
//按部门查看
export const taskLisSector = data => apiPost('/task/lis/sector', data); //角色权限
// 任务类型的转换
export const taskChange = data => apiPost('/task/sector/mod', data);

// 任务审核
export const taskCheckLis = data => apiPost('/task/check/lis', data); //审核池任务列表
export const checkTask = data => apiPost('/task/check', data); // 审核任务
export const uncheckAmount = data => apiGet('/task/uncheck/amount',data); // 待审核数量
export const delCheckTask = data => apiPost('/task/check/del',data) // 删除审核任务


// 易维护

export const getBelongLis = data => apiGet('/easym/belong', data); // 获取类型的列表
export const delEasyMainLis = data => apiPost('/easym/del', data); //删除易维护列表
export const getEasyMainLis = data => apiPost('/easym/lis', data); //获取易维护列表
export const addEasyMainNew = data => apiPost('/easym/new', data); //新建易维护项目
export const getEasyMainInfo = data => apiPost('/easym/info', data); //获取易维护项目详情 
export const modEasyMainInfo = data => apiPost('/easym/mod', data); //修改易维护项目详情 
export const modEasyMainNote = data => apiPost('/easym/note', data); //修改备忘录 
export const addComment = data => apiPost('/easym/comment/add', data); //新增评论
export const addMaintenance = data => apiPost('/easym/maintenance/add', data); //新增维保记录
export const addremind =  data => apiPost('/easym/remind/add', data); //新增项目提醒 

export const addFile =  data => apiPost('/easym/file/add', data); //新增文件
export const delFile =  data => apiPost('/easym/file/del', data); //删除文件
export const modFile =  data => apiPost('/easym/file/mod', data); //修改文件
export const getFileLis =  data => apiPost('/easym/file/lis', data); //获取文件列表

export const addRecord =  data => apiPost('/easym/record/add', data); //新增维修项目
export const delRecord =  data => apiPost('/easym/record/del', data); //删除维修项目
export const modRecord =  data => apiPost('/easym/record/mod', data); //修改维修项目
export const getRecordLis =  data => apiPost('/easym/record/lis', data); //获取维修项目


export const addHardware =  data => apiPost('/easym/hardware/add', data); //新增硬件
export const delHardware =  data => apiPost('/easym/hardware/del', data); //删除硬件
export const modHardware =  data => apiPost('/easym/hardware/mod', data); //修改硬件
export const getHardwareLis =  data => apiPost('/easym/hardware/lis', data); //获取硬件列表


// AX400
export const getAX400Start =  data => apiPost('/gpt/start', data); //第一次进入页面，新会话。
export const postAX400Ask = data =>  apiPost('/gpt/ask', data); //提问
export const postAX400Receive = data =>  apiPost('/gpt/receive', data); //接收答案

// AX400 2.0
export const getTitleHistoryLis = data => apiPost('/ax400/gettitle', data); // 获取历史记录
export const getHistorySessionLis = data  => apiPost('/ax400/gethistory', data); // 获取单个会话历史记录
export const askAx4002 = data  => apiPost('/ax400/ask', data); // 提问
export const getAx4002Receice = data => apiGet('/ax400/poll', data);// 接收答案
export const delAx4002Title = data => apiPost('/ax400/delete', data); // 删除历史记录


