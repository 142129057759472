import Vue from 'vue';
import VueRouter from 'vue-router';
import cookie from '@/utils/cookie';
import _isMobile from '@/_isMobile';
let isMobile = !!_isMobile();
const View = () => import('../views/View.vue');
const Communicate = isMobile
  ? () => import('../views/Chat/mobile/Communicate.vue')
  : () => import('../views/Chat/main/Communicate.vue');
const Group = isMobile ? () => import('../views/Chat/mobile/Group.vue') : () => import('../views/Chat/main/Group.vue');
const Contacts = isMobile
  ? () => import('../views/Chat/mobile/Contacts.vue')
  : () => import('../views/Chat/main/Contacts.vue');

const Progress = isMobile
  ? () => import('../views/Task/mobile/Progress.vue')
  : () => import('../views/Task/main/Progress.vue');
const Daily = isMobile ? () => import('../views/Task/mobile/Daily.vue') : () => import('../views/Task/main/Daily.vue');
const Help = isMobile ? () => import('../views/Task/mobile/Help.vue') : () => import('../views/Task/main/Help.vue');
const Disk = isMobile ? () => import('../views/Task/mobile/Disk.vue') : () => import('../views/Task/main/Disk.vue');
const tutorialManage = isMobile
  ? () => import('../views/Task/mobile/tutorialManagement.vue')
  : () => import('../views/Task/main/tutorialManagement.vue'); // 教程管理
const Ax400 = isMobile ? () => import('../views/Task/mobile/ax400.vue') : () => import('../views/Task/main/ax400.vue');
const NewAx400 = isMobile ? () => import('../views/Task/mobile/NewAx400.vue') : () => import('../views/Task/main/NewAx400.vue');
  // 易维护
const Easymaintain = isMobile
  ? () => import('../views/Maintain/mobile/easymaintain.vue')
  : () => import('../views/Maintain/main/easymaintain.vue'); 

import { postUserinfoWxBind } from '@/service/help';
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    redirect: '/task',
  },
  // {
  //   path: '/chat',
  //   name: 'Chat',
  //   redirect: '/chat/communicate',
  //   component: View,
  //   children: [
  //     {
  //       path: 'communicate',
  //       component: Communicate,
  //       meta: {
  //         mtitle: '沟通',
  //       },
  //     },
  //     {
  //       path: 'group',
  //       component: Group,
  //     },
  //     {
  //       path: 'contacts',
  //       component: Contacts,
  //     },
  //   ],
  // },
  {
    path: '/task',
    name: 'Task',
    component: View,
    redirect: '/task/progress',
    children: [
      {
        path: 'progress',
        component: Progress,
        meta: {
          mtitle: '任务进度',
        },
      },
      {
        path: 'daily',
        component: Daily,
        meta: {
          mtitle: '日事清',
        },
      },
      {
        path: 'tutorialManage',
        component: tutorialManage,
      },
      {
        path: 'help',
        component: Help,
      },
      {
        path: 'disk',
        component: Disk,
      },
      {
        path: 'ax400',
        component: Ax400,
      },
      {
        path:'newax400',
        component:NewAx400
      }
    ],
  },
  {
    path: '/maintain',
    name: 'Maintain',
    redirect: '/maintain/easymaintain',
    component: View,
    children: [
      {
        path: 'easymaintain',
        component: Easymaintain,
        meta: {
          mtitle: '易维护',
        },
      }
    ],
  },
];
if (isMobile) {
  routes.push({
    path: '/my',
    name: 'My',
    meta: {
      mtitle: '我的',
    },
    component: () => import('../views/My.vue'),
  });
  routes.push({
    path: '/login',
    name: 'Login',
    components: { login: () => import('../views/MobileLogin.vue') },
  });
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
// let str = 'https://p.eai9.com/?code=061TQ40w3Ubq0X21eT2w3GTIuG1TQ40k&state=#/task/progress'
router.beforeEach((to, from, next) => {
  console.log(to,'====即将要去的页面')
  let str = to.fullPath;
  let code = str.match(/code=(.*)+&/g);
  console.log(code,'===code')
  // this.$store.state.isLogin
  if (code !== null) {
    //已经获取到code 并且vuex可用
    //login方法
    console.log(code[0].substr(4));
    code = code[0].toString().substr(4);
    code = code.substring(code.length - 1, 1);
    setTimeout(() => {
      let store = window.Vue.$store;
      if (!store.state.isLogin) {
        console.log('还没登录');
        store
          .dispatch('login', {
            code,
            type: 'wx',
          })
          .then(res => {
            console.log('route', code, res);
            if (res.success) {
              let a = cookie(document).get('account');
              let p = cookie(document).get('password');
              cookie.remove('account');
              cookie.remove('password');
              console.log(a, p);
              cookie(document).set('account', a);
              cookie(document).set('password', p);
              router.push(to.path);
              store.commit('set', {
                type: 'isLogin',
                data: true,
              });
            } else {
              alert(res.msg);
              store.commit('set', {
                type: 'isLogin',
                data: false,
              });
            }
          })
          .catch(e => {
            //连接不上发生错误
            alert('该微信未绑定账号');
            store.commit('set', {
              type: 'isLogin',
              data: false,
            });
          });
      } else {
        console.log('已经登录，绑定微信', code);
        postUserinfoWxBind({
          code,
        }).then(r => {
          //绑定微信
          console.log('绑定微信', r);
          window.Vue.$message({
            type: 'success',
            message: '微信绑定成功',
          });
          router.push(to.path);
        });
      }
      next();
    }, 500);
  } else {
    next();
  }
});

if (isMobile) {
  //生成一个title供header使用
  router.beforeResolve((to, from, next) => {
    let store = window.Vue.$store || undefined;
    console.log(store,'====store=')
    // console.log(store);
    if (store) {
      handleResolve(to, next, store);
    } else {
      setTimeout(() => {
        store = window.Vue.$store;
        console.log(store);
        handleResolve(to, next, store);
      }, 0);
    }
  });
  let handleResolve = (to, next, store) => {
    if (cookie(document).get('name') !== undefined) {
      if (to.meta.mtitle) {
        store.commit('setMobilekv', {
          k: 'title',
          v: to.meta.mtitle,
        });
      }
      next();
    } else if (cookie(document).get('name') == undefined) {
      store.commit('set', {
        type: 'isLogin',
        data: false,
      });
      if (to.name != 'Login') {
        next({
          name: 'Login',
          query: {
            redirect: to.fullPath,
          },
        });
      } else {
        next();
      }
    }
  };
}
export default router;
